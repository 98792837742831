import React, { useState } from "react";
import styled from "styled-components";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import { H3, P1, A } from "app/components/Typography";
import { Playlist } from "app/components/Icon";
import { CreatePlaylistModal } from "app/components/PlaylistComponents/PlaylistModals";

const StyledIcon = styled.i`
  color: ${({ theme }) => theme.colors.monochrome[3]};
`;

export function EmptyState() {
  const [isCreatePlaylistModalOpen, setIsCreatePlaylistModalOpen] = useState(
    false
  );

  return (
    <>
      <Flex
        mt={{ _: 3, md: 6 }}
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Flex flexDirection="column" alignItems="center" textAlign="center">
          <Div mb={4}>
            <StyledIcon as={Playlist} width="52.5px" height="35px" />
          </Div>
          <H3 fontWeight="bold" color="monochrome.6" mb={2}>
            Create a playlist to start organizing classes
          </H3>
          <P1 color="monochrome.5" textTransform="none">
            Click <A onClick={() => setIsCreatePlaylistModalOpen(true)}>here</A>{" "}
            to start creating a playlist!
          </P1>
        </Flex>
      </Flex>
      {isCreatePlaylistModalOpen && (
        <CreatePlaylistModal
          closeModal={() => setIsCreatePlaylistModalOpen(false)}
        />
      )}
    </>
  );
}
