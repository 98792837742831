import React, { useState } from "react";
import Flex from "app/components/Flex";
import { NavFilterButton } from "app/components/Button/NavFilterButton";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Icon, { PlusBox } from "app/components/Icon";
import { playlistsSelectElement } from "services/typewriter/segment";
import {
  ANALYTICS_ELEMENT_NAME,
  ANALYTICS_ELEMENT_TYPE,
  ANALYTICS_LOCATION,
} from "constants/index";
import { Routes } from "constants/routes";
import { H1 } from "app/components/Typography";
import { ScreenGtMd } from "app/components/MediaQueries";
import { getAnalyticsModuleFromPath } from "helpers/analytics";
import { CreatePlaylistModal } from "app/components/PlaylistComponents/PlaylistModals";
import { useGetUserIsAnonymous } from "modules/selectors";
import { CtaButton, NavFilters } from "./styles";

interface Props {
  children: React.ReactNode;
}

export function RouteWrapper({ children }: Props) {
  const location = useLocation();
  const history = useHistory();
  const isAnonymous = useGetUserIsAnonymous();
  const { url } = useRouteMatch();
  const [isCreatePlaylistModalOpen, setIsCreatePlaylistModalOpen] = useState(
    false
  );

  return (
    <>
      <Flex
        flexDirection="column"
        width="100%"
        margin="0 auto"
        p="24px"
        pb="75px"
        maxWidth="1600px"
      >
        <Flex
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          mb="16px"
        >
          <H1>Playlists</H1>
          {!isAnonymous && (
            <CtaButton
              onClick={() => {
                playlistsSelectElement({
                  element_name: ANALYTICS_ELEMENT_NAME.playlistCreateButton,
                  element_type: ANALYTICS_ELEMENT_TYPE.button,
                  location: ANALYTICS_LOCATION.playlists,
                  module: getAnalyticsModuleFromPath(location.pathname),
                });
                setIsCreatePlaylistModalOpen(true);
              }}
            >
              <Icon as={PlusBox} color="white" height="16px" width="16px" />
              <ScreenGtMd>Create Playlist</ScreenGtMd>
            </CtaButton>
          )}
        </Flex>
        {!isAnonymous && (
          <NavFilters>
            <NavFilterButton
              text="My Playlists"
              isSelected={url === Routes.playlists__created}
              onClick={() => history.push(Routes.playlists__created)}
            />
            <NavFilterButton
              text="STEEZY Playlists"
              isSelected={url === Routes.playlists__steezy}
              onClick={() => history.push(Routes.playlists__steezy)}
            />
            <NavFilterButton
              text="Followed Playlists"
              isSelected={url === Routes.playlists__following}
              onClick={() => history.push(Routes.playlists__following)}
            />
          </NavFilters>
        )}
        {children}
      </Flex>
      {isCreatePlaylistModalOpen && (
        <CreatePlaylistModal
          closeModal={() => setIsCreatePlaylistModalOpen(false)}
        />
      )}
    </>
  );
}
