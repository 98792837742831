import styled from "styled-components";

export const CARD_RATIO = 130 / 280;

export const PlaylistCardWrapper = styled.a<{ onClick(e: any): void }>`
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.black};

  :hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const ThumbnailContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  padding-top: ${CARD_RATIO * 100}%;
  position: relative;
`;

export const ThumbnailWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  mask-image: -webkit-radial-gradient(white, black);
`;
