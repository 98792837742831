import React, { useEffect } from "react";
import { Waypoint } from "app/components/Waypoint";
import { playlistsViewScreen } from "services/typewriter/segment";
import {
  ANALYTICS_LOCATION,
  ANALYTICS_MODULE,
  ANALYTICS_SCREEN_NAME,
} from "constants/analytics";
import LoaderFullCentered from "app/components/Loader/LoaderFullCentered";
import { PlaylistCard } from "app/components/PlaylistCard";
import { PersonalizedPlaylistCard } from "app/components/PlaylistCard/PersonalizedPlaylistCard";
import { RouteWrapper } from "../RouteWrapper";
import { useGetCreatedPlaylists } from "./hooks";
import { PlaylistListContainer } from "../styles";
import { EmptyState } from "./EmptyState";

const Playlists = () => {
  const {
    myPlaylists,
    personalizedPlaylist,
    pageInfoMyPlaylists,
    fetchMoreMyPlaylists,
    isLoading,
  } = useGetCreatedPlaylists();

  useEffect(() => {
    playlistsViewScreen({
      screen_name: ANALYTICS_SCREEN_NAME.myPlaylists,
      location: ANALYTICS_LOCATION.playlists,
      module: ANALYTICS_MODULE.my_playlists,
    });
  }, []);

  if (isLoading) {
    return <LoaderFullCentered />;
  }

  if (
    myPlaylists.length === 0 &&
    (!personalizedPlaylist || personalizedPlaylist.length === 0)
  ) {
    return <EmptyState />;
  }

  return (
    <PlaylistListContainer>
      <PersonalizedPlaylistCard personalizedPlaylist={personalizedPlaylist} />
      {myPlaylists.map(playlist => (
        <PlaylistCard playlistData={playlist} key={playlist.id} />
      ))}
      <Waypoint
        hasMore={pageInfoMyPlaylists?.hasNextPage}
        fetchData={fetchMoreMyPlaylists}
      />
    </PlaylistListContainer>
  );
};

export default () => {
  return (
    <RouteWrapper>
      <Playlists />
    </RouteWrapper>
  );
};
