import React from "react";
import { PlaylistV2 } from "services/graphql";
import Thumbnail from "app/components/Thumbnail";
import { useHistory, useLocation } from "react-router";
import { H3, P } from "app/components/Typography";
import Icon, { Playlist } from "app/components/Icon";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import { contentSelectPlaylist } from "services/typewriter/segment";
import {
  getAnalyticsLocationFromPath,
  getAnalyticsModuleFromPath,
} from "helpers/analytics";
import {
  PlaylistCardWrapper,
  ThumbnailContainer,
  ThumbnailWrapper,
} from "./styles";

interface Props {
  playlistData: Partial<PlaylistV2>;
}

export function PlaylistCard({ playlistData }: Props) {
  const location = useLocation();
  const history = useHistory();

  const routeToPlaylist = `/playlists/${playlistData.id}`;

  const handleClick = (e: any) => {
    e.preventDefault(); // To ignore link tag href
    contentSelectPlaylist({
      playlist_id: playlistData.id,
      playlist_name: playlistData.name,
      playlist_user_id: playlistData.owner?.uid || "steezy",
      location: getAnalyticsLocationFromPath(location.pathname),
      module: getAnalyticsModuleFromPath(location.pathname),
    });
    history.push(routeToPlaylist);
  };

  return (
    <PlaylistCardWrapper onClick={handleClick} href={routeToPlaylist}>
      <Div m="0 16px 16px 0" position="relative">
        {/* SHADOWS FOR PLAYLISTS */}
        <Div
          bg="monochrome.2"
          width="100%"
          height="100%"
          position="absolute"
          top="16px"
          left="16px"
          borderRadius="8px"
        />
        <Div
          bg="monochrome.4"
          width="100%"
          height="100%"
          position="absolute"
          top="8px"
          left="8px"
          borderRadius="8px"
        />
        <ThumbnailContainer>
          <ThumbnailWrapper>
            {playlistData.thumbnailImageUrl ? (
              <>
                <Thumbnail
                  src={`${playlistData.thumbnailImageUrl}&w=600`}
                  placeholderSrc={`${playlistData.thumbnailImageUrl}?blur=200`}
                />
                <Flex
                  bg="monochrome.7"
                  position="absolute"
                  height="24px"
                  width="24px"
                  top="10px"
                  left="10px"
                  p="4px"
                  borderRadius="2px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon
                    as={Playlist}
                    height="100%"
                    width="100%"
                    color="white"
                  />
                </Flex>
              </>
            ) : (
              <Flex
                bg="monochrome.2"
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Icon
                  as={Playlist}
                  height="40px"
                  width="40px"
                  color="monochrome.7"
                />
              </Flex>
            )}
          </ThumbnailWrapper>
        </ThumbnailContainer>
      </Div>
      <H3 mt="8px">{playlistData.name}</H3>
      <P fontSize="12px">
        Created by @
        {playlistData.owner ? playlistData.owner.username : "STEEZY"}
      </P>
    </PlaylistCardWrapper>
  );
}
