import { useGetMyPlaylists, useGetMyPersonalizedPlaylist } from "../hooks";

export function useGetCreatedPlaylists() {
  const {
    myPlaylists,
    pageInfoMyPlaylists,
    fetchMoreMyPlaylists,
    isFetchingInitialMyPlaylists,
  } = useGetMyPlaylists({});
  const {
    personalizedPlaylist,
    loadingPersonalizedPlaylist,
  } = useGetMyPersonalizedPlaylist();

  if (loadingPersonalizedPlaylist) {
    return {
      isLoading: true,
    };
  }

  return {
    myPlaylists,
    pageInfoMyPlaylists,
    fetchMoreMyPlaylists,
    isLoading: isFetchingInitialMyPlaylists,
    personalizedPlaylist,
  };
}
