import React from "react";
import Thumbnail from "app/components/Thumbnail";
import { useHistory } from "react-router";
import { H3 } from "app/components/Typography";
import Icon, { Playlist } from "app/components/Icon";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import { Routes } from "constants/routes";
import env from "helpers/env";
import {
  PlaylistCardWrapper,
  ThumbnailContainer,
  ThumbnailWrapper,
} from "./styles";

interface Props {
  personalizedPlaylist: any;
}

export function PersonalizedPlaylistCard({ personalizedPlaylist }: Props) {
  const history = useHistory();

  const handleClick = (e: any) => {
    e.preventDefault(); // To ignore link tag href
    history.push(Routes.playlists__weekly_recommendations);
  };

  if (!personalizedPlaylist) {
    return null;
  }

  const firstPlaylistClass = personalizedPlaylist[0];

  return (
    <PlaylistCardWrapper
      onClick={handleClick}
      href={Routes.playlists__weekly_recommendations}
    >
      <Div m="0 16px 16px 0" position="relative">
        {/* SHADOWS FOR PLAYLISTS */}
        <Div
          bg="monochrome.2"
          width="100%"
          height="100%"
          position="absolute"
          top="16px"
          left="16px"
          borderRadius="8px"
        />
        <Div
          bg="monochrome.4"
          width="100%"
          height="100%"
          position="absolute"
          top="8px"
          left="8px"
          borderRadius="8px"
        />
        <ThumbnailContainer>
          <ThumbnailWrapper>
            {firstPlaylistClass.thumbnail ? (
              <>
                <Thumbnail
                  src={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
                    firstPlaylistClass.thumbnail
                  }-basic.jpg?w=600&auto=format}`}
                  placeholderSrc={`${env(
                    "PUBLIC_ASSET_PATH"
                  )}/class-thumbnails/${
                    firstPlaylistClass.thumbnail
                  }-basic.jpg?blur=200`}
                />
                <Flex
                  bg="monochrome.7"
                  position="absolute"
                  height="24px"
                  width="24px"
                  top="10px"
                  left="10px"
                  p="4px"
                  borderRadius="2px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon
                    as={Playlist}
                    height="100%"
                    width="100%"
                    color="white"
                  />
                </Flex>
              </>
            ) : (
              <Flex
                bg="monochrome.2"
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Icon
                  as={Playlist}
                  height="40px"
                  width="40px"
                  color="monochrome.7"
                />
              </Flex>
            )}
          </ThumbnailWrapper>
        </ThumbnailContainer>
      </Div>
      <H3 mt="8px">Your Weekly Recommendations</H3>
    </PlaylistCardWrapper>
  );
}
